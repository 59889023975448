<template>
    <div class="container u-f-ajb u-d">
        <Header :type="1"></Header>
        <div class="main u-f-ajc u-d u-f1">
            <img class="img-slogan" src="../assets/slogan.svg" alt="slogan" />
            <div class="btns">
                <button class="btn" type="button" @click="onClickBtn(1)" v-preventReClick>公司介绍</button>
                <button class="btn" type="button" @click="onClickBtn(2)" v-preventReClick>官网建设申请</button>
                <button class="btn" type="button" @click="onClickBtn(3)" v-preventReClick>艺术商演申请</button>
            </div>
        </div>
        <Footer :type="1"></Footer>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
	name: 'Home',
	components: {
		Header,
		Footer
	},
	methods: {
		onClickBtn(index) {
            if (index === 1) {
                this.$router.replace({ name: 'About', params: { index: index } });
            } else if (index === 2) {
                this.$router.replace({ name: 'ApplyWebsit', params: { index: index } });
            } else if (index === 3) {
                this.$router.replace({ name: 'ApplyShow', params: { index: index } });
            }

		}
	}
};
</script>

<style type="text/css" scoped="scoped">
/* 在宽大于或等于 1920 像素的屏幕上，将背景图片设置为拉伸 */
@media screen and (min-width: 1920px) {
    .container {
        background-size: 100%;
    }
}
/* 在高大于或等于 1080 像素的屏幕上，将背景图片设置为拉伸 */
@media screen and (min-height: 1080px) {
    .container {
        background-size: auto 100%;
    }
}
/* 在宽小于或等于 1920 像素的屏幕上，将背景图片设置为拉伸 */
@media screen and (max-width: 750px) {
    .img-slogan {
        width: 337.5px;
        height: 120.5px;
    }
    .btns {
        display: flex;
        flex-direction: column;
    }
}
.container {
    height: 100%;
    background-color: #ffffff;
    background-image: url(../assets/1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    overflow: hidden;
}

.btn {
    width: 180px;
    height: 60px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding: 0;
    margin: 12px;
    border: none;
}
.img-slogan {
    margin-bottom: 76px;
}
</style>
