<template>
	<div :class="type === 1 ? 'header1' : 'header2'" class="u-f-ajb">
		<img class="img-logo" src="../assets/logo_heng.svg" alt="logo" />
		<div v-if="type !== 1" class="items u-f-ajb u-f1">
			<div class="item u-f-ajc u-f1" @click="onClickItem(0)"><span :class="currentIndex === 0 ? 'span-sel' : ''">首页</span></div>
			<div class="item u-f-ajc u-f1" @click="onClickItem(1)"><span :class="currentIndex === 1 ? 'span-sel' : ''">公司介绍</span></div>
			<div class="item u-f-ajc u-f1" @click="onClickItem(2)"><span :class="currentIndex === 2 ? 'span-sel' : ''">官网建设申请</span></div>
			<div class="item u-f-ajc u-f1" @click="onClickItem(3)"><span :class="currentIndex === 3 ? 'span-sel' : ''">艺术商演申请</span></div>
		</div>
		<el-popover class="popover" v-if="type !== 1" trigger="click" placement="top" width="150">
			<div class="u-f-ajc u-d">
				<div class="hover-item u-f-ajc" @click="onClickItem(0)">首页</div>
				<div class="hover-item u-f-ajc" @click="onClickItem(1)">公司介绍</div>
				<div class="hover-item u-f-ajc" @click="onClickItem(2)">官网建设申请</div>
				<div class="hover-item u-f-ajc" @click="onClickItem(3)">艺术商演申请</div>
			</div>
			<div class="u-f-ajc u-d" slot="reference">
				<img class="img-menu" src="../assets/menu.svg" alt="qrcode" />
			</div>
		</el-popover>
	</div>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {
			currentIndex: 1
		};
	},
	props: {
		type: {
			type: Number,
			default: 1
		},
		current: {
			type: Number,
			default: 1
		}
	},
	methods: {
		onClickItem(index) {
			if (this.currentIndex === index) return;
			this.currentIndex = index;
			this.$emit('onClickTab', index);
		}
	},
	watch: {
		current(val) {
			console.log(val);
			if (val !== this.currentIndex) {
				this.currentIndex = val;
			}
		}
	},
	created() {
		this.currentIndex = this.current;
	}
};
</script>

<style scoped lang="less">
/* 在宽小于或等于 1000px 像素的屏幕上 */
@media screen and (max-width: 1000px) {
	.header1,.header2 {
		padding: 0 10px !important;
	}
	.items{
		display: none;
	}
}
/* 在宽小于或等于 1000px 像素的屏幕上 */
@media screen and (min-width: 1000px) {
	.popover{
		display: none;
	}
}
.header1 {
	color: white;
	width: 100%;
	height: 100px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
	padding: 0 19%;
	box-sizing: border-box;
}
.header2 {
	color: white;
	width: 100%;
	height: 100px;
	background: #333333;
	padding: 0 19%;
	box-sizing: border-box;
}
.items {
	max-width: 640px;
	font-size: 16px;
	color: #cbcbcb;
}
.item {
	max-width: 160px;
	height: 40px;
	position: relative;
}
.item:before {
	content: '';
	width: 1px;
	height: 30%;
	background-color: #cbcbcb;
	position: absolute;
	right: 0;
	top: 35%;
}
.hover-item{
	width: 150px;
	height: 40px;
}
.hover-item:hover{
	background-color: #E5E5E5;
}
.span-sel {
	font-weight: 500;
	color: #ffffff;
}
.span-sel:before {
	content: '';
	height: 2px;
	border-radius: 1px;
	width: 30%;
	background-color: #ffffff;
	position: absolute;
	bottom: 0;
	left: 35%;
}
.item:last-child:before {
	background-color: transparent;
}
.img-menu{
	width: 24px;
}
</style>
