<template>
	<div :class="type == 1 ? 'footer1' : 'footer2'" class="u-d">
		<div class="div-info u-f-ajb u-f1">
			<div class="div-info-address">
				<div>联系电话：0571 - 88683129</div>
				<div>企业邮箱：public@yiyun.show</div>
				<div>公司地址：浙江省杭州市余杭区仓前街道文一西路1378号（师范大学科技园A座415-1）</div>
			</div>
			<el-popover trigger="hover" placement="top" width="200">
				<img class="img-qrcode" style="width: 200px; height: 200px" src="../assets/qrcode_small.svg" alt="qrcode" />
				<div class="u-f-ajc u-d" slot="reference">
					<img class="img-qrcode" src="../assets/qrcode_small.svg" alt="qrcode" />
					<div class="div-tip">微信公众号</div>
				</div>
			</el-popover>
		</div>
		<div class="u-f-ajc">
			<img class="img-beian" src="../assets/beian.png" alt="beian" />
			<div class="div-tip" @click="onClick(1)">浙公网安备33011002015868号</div>
			<div class="div-tip" @click="onClick(2)">浙ICP备2021017063号</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	props: {
		type: {
			type: Number,
			default: 1
		}
	},
	methods: {
		onClick(index) {
			if (index == 1) {
				open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002015868');
			} else if (index == 2) {
				open('https://beian.miit.gov.cn');
			}
		}
	}
};
</script>

<style scoped lang="less">
/* 在宽小于或等于 1000 像素的屏幕 */
@media screen and (max-width: 1000px) {
	.footer1 {
		padding: 0 !important;
	}
	.div-info {
		justify-content: center !important;
	}
	.div-info-address {
		display: none;
	}
}
.footer1 {
	font-size: 14px;
	color: white;
	width: 100%;
	height: 100px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
	padding: 0 19%;
	box-sizing: border-box;
}
.footer2 {
	font-size: 14px;
	color: white;
	width: 100%;
	height: 100px;
	background: #333333;
	padding: 0 19%;
	box-sizing: border-box;
}
.img-beian {
	width: 15px;
	height: 15px;
}
.div-tip {
	font-size: 12px;
	line-height: 20px;
	margin: 0 10px;
	cursor: pointer;
}
</style>
